<template>
  <div class="wrap">
    <!-- <div id="paypal-button-container" class="paypal-button-container"></div> -->
    <!-- Containers for Card Fields hosted by PayPal -->
    <img src="https://wx.playhy.com/card-mark.png" alt="">

    <div class="orderDetail">
      <div class="title">订单明细</div>
      <div class="name">商品名称：{{money}}元档位</div>
      <div class="price"><span>订单总额：</span><span class="num">{{money_usd}}USD</span></div>
    </div>

    <div id="card-form" class="card_container">
      <div id="card-number-field-container"></div>
      <div id="card-expiry-field-container"></div>
      <div id="card-cvv-field-container"></div>
      <button v-show="isShowButton" id="card-field-submit-button" type="button">Pay now with Card</button>
    </div>
    <div v-if="!isShowButton" class="info">正在付款中，请稍后</div>
  </div>
</template>
<script>
// import {getClientConfig, getScriptBaseUrlUrl} from '@/utils/paypalcardCommon';
import { createOrder, onApprove } from "@/utils/paycard6";
import { Toast } from "vant";
import {
  getUsdMoney
} from "@/api/stripePay";
export default {
  data() {
    return {
      //  未加载完成禁止点击
      isDisabled: false,
      isShowButton: true,
      productName:'',
      money_usd:'',
      money:'',
    }
  },
  created(){
    this.getOrderDetail()
  },  
  methods: {
    //  js加载
    loadScript(url, callback) {
      const el = document.querySelector(`script[src="${url}"]`);
      if (!el) {
        const script = document.createElement('script');
        script.setAttribute('src', url);
        script.onload = callback;
        document.head.insertBefore(script, document.head.firstElementChild);
      }
    },
    getOrderDetail(){
      getUsdMoney({
        order_num: this.$route.query.number
      }).then((res) => {
        Toast.clear(true)
        this.productName = res.props_name;
        this.money = res.money;
        this.money_usd = res.money_usd;
      }).catch(() => {
        this.$toast.fail('获取订单信息失败')
        Toast.clear(true)
      });
    }
  },
  mounted: function () {
    this.loadScript('https://www.paypal.com/sdk/js?components=buttons,card-fields&client-id=ATm6pMZ8w9Z602T2upM3_hlmKmhqcUqlDSsU5NhDpBAEIeixluIEr9C-eNG2q2uz2yzvkPwnx0qSNmRA&locale=en_US', () => {
      // window.paypal
      //   .Buttons({
      //     createOrder: createOrder,
      //     onApprove: onApprove,
      //   })
      //   .render("#paypal-button-container");

      const cardField = window.paypal.CardFields({
        createOrder: function (data, actions) {
          return createOrder(data, actions)
        },
        //  订单验证
        onApprove: function (data, actions) {
          return onApprove(data, actions)
        },
        // 点击付款按钮时调用 通常用于表单验证
        onClick: (data) => {
          console.log(data)
          console.log("点击弹出银行卡输入界面！")
          // const card_number = document.getElementById('#credit-card-number');
          // card_number.textContent(123);
        },
      });

      // Render each field after checking for eligibility
      console.log('打印cardField' + JSON.stringify(cardField))
      if (cardField.isEligible()) {
        // const nameField = cardField.NameField();
        // nameField.render("#card-name-field-container");

        const numberField = cardField.NumberField();
        numberField.render("#card-number-field-container");

        const cvvField = cardField.CVVField();
        cvvField.render("#card-cvv-field-container");

        const expiryField = cardField.ExpiryField();
        expiryField.render("#card-expiry-field-container");

        // Add click listener to submit button and call the submit function on the CardField component
        document
          .getElementById("card-field-submit-button")
          .addEventListener("click", () => {
            console.log('点击支付按钮')
            this.isShowButton = false;
            Toast.loading({
              message: "正在付款中...",
              forbidClick: true,
              duration: 0,
            });
            cardField.submit(

            ).catch((error) => {
              console.log('错误信息123', error)
              Toast.clear(true)
              this.isShowButton = true;
              this.$toast(`Sorry, your transaction could not be processed\n${error}`)
              // setTimeout(function () {
              //   window.location.reload();
              // },1000)
            });
          });
      } else {
        // Hides card fields if the merchant isn't eligible
        // document.querySelector("#card-form").style = "display: none";
      }
    });
    //  加载完成允许点击
    this.$nextTick(() => {
      console.log('加载完成')
      this.isDisabled = true;
    })
  }
}
</script>
<style scoped>
.wrap {
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  box-sizing: border-box;
  text-align: center;
}

.orderDetail {
  text-align: left;
  background: rgba(246, 247, 248, 0.7);
  padding: 16px;
  margin: 16px auto;
}

.orderDetail .title {
  font-size: 14px;
  font-weight: 700;
  color: rgb(66, 71, 77);
  margin-bottom: 16px;
}

.orderDetail .name {
  font-size: 14px;
  color: rgb(66, 71, 77);
  margin-bottom: 16px;
}

.orderDetail .price {
  font-size: 14px;
  color: rgb(66, 71, 77);
}

.orderDetail .price span{
  vertical-align: middle;
}

.orderDetail .num{
  font-weight: 700;
  font-size: 24px;
  color: rgb(26, 29, 33);
}

.info {
  color: red;
}

.paypal-button-container {
  border-radius: 5px;
  background-color: #FFFFFF;
  padding: 20px 0;
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
}

.card_container {
  border-radius: 5px;
  background-color: #FFFFFF;
  padding: 20px 0;
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
}

.card_field {
  width: 100%;
  padding: 12px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  height: 40px;
  background: white;
  font-size: 17px;
  color: #3a3a3a;
  font-family: helvetica, tahoma, calibri, sans-serif;
}

.card_field_50 {
  width: 50%;
  padding: 12px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  height: 40px;
  background: white;
  font-size: 17px;
  color: #3a3a3a;
  font-family: helvetica, tahoma, calibri, sans-serif;
}

.card_field_75 {
  width: 75%;
  padding: 12px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  height: 40px;
  background: white;
  font-size: 17px;
  color: #3a3a3a;
  font-family: helvetica, tahoma, calibri, sans-serif;
}

.row {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap;
  /* IE10 */
  flex-wrap: wrap;
  margin: 0 -16px;
}

.col-25 {
  -ms-flex: 25%;
  /* IE10 */
  flex: 25%;
}

.col-50 {
  -ms-flex: 50%;
  /* IE10 */
  flex: 50%;
}

input[type=text],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
  height: 40px;
  background: white;
  font-size: 17px;
  color: #3a3a3a;
  font-family: helvetica, tahoma, calibri, sans-serif;
}

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.message_container {
  border-radius: 5px;
  background: #FFFFFF;
  font-size: 13px;
  font-family: monospace;
  padding: 20px;
}

#loading {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  opacity: 0.7;
  background-color: #fff;
  z-index: 99;
  text-align: center;
}

#loading-image {
  position: absolute;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  /* half width of the spinner gif */
  margin-top: -50px;
  /* half height of the spinner gif */
  text-align: center;
  z-index: 1234;
  overflow: auto;
  width: 100px;
  /* width of the spinner gif */
  height: 102px;
  /* height of the spinner gif +2px to fix IE8 issue */
}

.button_container {
  display: flex;
  justify-content: center;
}

button:hover {
  background-color: powderblue;
  margin-top: 20px;
}

button {
  width: 229px;
  height: 49px;
  background: lightblue;
  border: 1px dotted black;
  font-size: 17px;
  color: #3a3a3a;
  padding: 12px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
}

.btn_small {
  width: 130px;
  height: 39px;
  background: lightblue;
  border: 1px dotted black;
  font-size: 14px;
  color: #3a3a3a;
}

.btn_small:hover {
  background-color: powderblue;
}

html {
  font-size: 75px !important;
}

#paypal-button-container {
  display: flex;
}

img {
  width: 100% !important;
  /*position: absolute;*/
}
</style>
  